import React from 'react'

function ClosePanelIcon() {
	return (
		<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.8185 0V14" stroke="#6f6e84"></path>
			<rect x="0.5" y="0.5" width="19" height="13" rx="1.5" stroke="#6f6e84"></rect>
			<path d="M3.63641 4.375H9.09096" stroke="#6f6e84"></path>
			<path d="M3.63641 7H9.09096" stroke="#6f6e84"></path>
			<path d="M3.63641 9.625H9.09096" stroke="#6f6e84"></path>
		</svg>
	)
}

export default ClosePanelIcon
